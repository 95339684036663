import axiosApi from '@/api/axiosApi';

//客户端查询所有
export const listAll = (data) => axiosApi({
  method: 'POST',
  url: '/intserv/question/listAll',
  data,
});

//客户端查询详情
export const detail = (data) => axiosApi({
  method: 'POST',
  url: '/intserv/question/detail',
  data,
});
