import dictionaryApi from '@/api/Dictionary';

/**
 * 下载方法
 * @param response
 */
export const downloadFile = (response) => {
  const res = response.data;
  const type = res.type;
  if (type.includes('application/json')) {
    let reader = new FileReader();
    reader.onload = e => {
      if (e.target.readyState === 2) {
        let data = JSON.parse(e.target.result);
        this.$message({
          message: data.msg,
          type: 'warning',
        });
      }
    };
    reader.readAsText(res);
  } else {
    let disposition = response.headers['content-disposition'];
    let fileName = '下载文件.zip';
    if (disposition) {
      let respcds = disposition.split(';');
      for (let i = 0; i < respcds.length; i++) {
        let header = respcds[i];
        if (header !== null && header !== '') {
          let headerValue = header.split('=');
          if (headerValue !== null && headerValue.length > 0) {
            if (headerValue[0].trim().toLowerCase() === 'filename') {
              fileName = decodeURI(headerValue[1]);
              break;
            }
          }
        }
      }
    }
    //处理引号
    if ((fileName.startsWith('\'') || fileName.startsWith('"')) &&
        (fileName.endsWith('\'') || fileName.endsWith('"'))) {
      fileName = fileName.substring(1, fileName.length - 1);
    }
    let blob = new Blob([res]);
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(link.href);
  }
};
/**
 * 初始化字典 后台管理系统配置的字典
 * @param codes
 * @param dicts
 */
export const initDicts = (codes, dicts = {}) => {
  if (typeof (codes) === 'string') {
    codes = [codes];
  }
  return new Promise((resolve, reject) => {
    if (codes && codes.length > 0) {
      dictionaryApi.codes(codes, {isHolowits: true}).then(response => {
        const res = response.data;
        for (const code of codes) {
          dicts[code] = res.data[code];
        }
        resolve(dicts)
      }).catch(err=>{
        reject(err);
      });
    } else {
      reject(null);
    }
  });

};

export function randomNum(len, radix) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  const uuid = []
  radix = radix || chars.length

  if (len) {
    // Compact form
    for (let i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix ]
  } else {
    // rfc4122, version 4 form
    let r

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
    uuid[14] = '4'

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16
        uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r]
      }
    }
  }
  return uuid.join('') + new Date().getTime()
}
