import axiosApi from './axiosApi.js'

const apiList = {
  codes: {
    method: 'POST',
    url: `/oauth/dictionary/codes`,
  },
}

export default {
  codes (data,meta) {
    return axiosApi({
      ...apiList.codes,
      data,
      meta
    })
  },
}
