<template>
  <div class="faq-page">
    <div class="faq-page-title">
      <div class="container">FAQ</div>
    </div>
    <div class="faq-search">
      <div class="search-bar">
        <input class="search-input" type="text" :placeholder="$i18n.t('faq.placeholder')"
               v-model="queryParam.question"
               @keyup.enter="search">
        <div class="search-button" @click="search">
          <i class="el-icon-search search-icon"/>
        </div>
      </div>
      <div class="help-btn">
        <div class="help-btn-popup">{{$i18n.t('faq.help')}}</div>
      </div>
    </div>
    <div class="container">
      <div class="faq-search-result ">
        <div class="faq-search-result-menu">
          <div class="faq-search-result-menu-item" :class="{'result-menu-active':queryParam.category===category.code}"
               v-for="(category,index) in FAQCategory" :key="index" @click="menuItemClick(category)">
            {{ $i18n.t(`faq.${category.name}`) }}
          </div>
        </div>
        <div class="faq-search-result-content">
          <el-empty :image-size="180" v-if="resultData.length===0"/>
          <div class="faq-search-result-item" v-for="(item,index) in resultData" :key="index" @click="toDetail(item)">
            {{ (index + 1) + '.' + item.question }}
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {initDicts} from '@/utils/commons';
  import {listAll} from '@/api/faq';

  export default {
    name: 'faq',
    data() {
      return {
        FAQCategory: [],
        queryParam: {
          'category': '',
          'question': '',
          'status': true,
        },
        resultData: [],
      };
    },
    mounted() {
      initDicts(['FAQCategory']).then(res => {
        this.FAQCategory = res.FAQCategory;
        try {
          this.queryParam.category = res.FAQCategory[0].code;
          this.fetch();
        } catch (e) {

        }
      });
    },
    methods: {
      toDetail(item) {
        let routeUrl = this.$router.resolve({
          path: `/FAQ/${item.id}/detail`,
        });
        window.open(routeUrl.href, '_blank');
      },
      menuItemClick(category) {
        if (this.queryParam.category === category.code) return;
        this.queryParam.category = category.code;
        this.fetch();
      },
      search() {
        this.queryParam.category = '';
        this.fetch();
      },
      fetch() {
        listAll(this.queryParam).then(res => {
          this.resultData = res.data.data;
        });
      },
    },
  };
</script>

<style scoped lang="less">
  .faq-page {
    position: relative;
    padding-bottom: 20px;
    background-color: #F8F8F8;

    &:after {
      content: '';
      background-color: #F8F8F8;
      position: absolute;
      height: 100vh;
      left: 0;
      top: 0;
      right: 0;
      z-index: -1;
    }

    @media (min-width: 1200px) {
      .container {
        width: auto;
        max-width: 1440px;
      }
    }


    .faq-page-title {
      font-size: 50px;
      font-weight: 400;
      color: #3D3D3D;
      border: 1px solid #EAEAEA;
      padding: 12px 0;
    }

    .faq-search {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 0;
    }

    .help-btn {
      width: 30px;
      height: 30px;
      background-image: url("../../assets/image/faq/icon_question.png");
      background-size: 24px;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      margin: 0 10px;
      cursor: pointer;

      &:hover {
        .help-btn-popup {
          opacity: 1;
          pointer-events: auto;
          transition-delay: 0s;
        }
      }

      .help-btn-popup {
        position: absolute;
        left: 36px;
        top: 3px;
        height: 38px;
        line-height: 38px;
        background: #FFFFFF;
        box-shadow: 0 0 5px 1px rgba(123, 123, 123, 0.27);
        border-radius: 0 8px 8px 8px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        padding: 0 14px;
        width: auto;
        white-space: nowrap;
        cursor: pointer;
        transition: .2s ease-out;
        opacity: 0;
        pointer-events: auto;
        transition-delay: .3s;
        user-select: none;

        &:hover {
          color: #C7000B;
        }

        &:active {
          background-color: #f8f8f8;
        }
      }
    }

    .faq-search-result {
      display: flex;
      background-color: #FFFFFF;
      min-height: 650px;

      .faq-search-result-menu {
        min-width: 269px;
        border-right: 1px solid #F1F1F1;
      }

      .faq-search-result-menu-item {
        height: 78px;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        position: relative;
        cursor: pointer;

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 2px;
          height: 36px;
          background: #C7000B;
          border-radius: 7px;
          opacity: 0;
        }

        &:hover {
          background-color: #f5f5f5;
        }

        &.result-menu-active {
          color: #C7000B;

          &:after {
            opacity: 1;
          }
        }
      }

      .faq-search-result-content {
        padding: 26px 46px;
        flex: 1;
        width: 100%;

        .faq-search-result-item {
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          line-height: 25px;
          margin-bottom: 20px;
          transition: .1s;
          cursor: pointer;

          &:hover {
            color: #C7000B;
          }
        }
      }
    }
  }

  .search-bar {
    width: 80%;
    max-width: 750px;
    background-color: transparent;
    height: 50px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    z-index: 2;
    //box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.08);
    border: 0.3px solid #C7000B;

    .search-icon {
      font-size: 24px;
      color: #FFFFFF;
      position: relative;
      z-index: 10;
    }

    .search-input {
      flex: 1;
      width: 100%;
      height: 50px;
      box-shadow: none;
      outline: none;
      font-size: 16px;
      font-family: "Microsoft Yahei", sans-serif;
      padding-left: 22px;
      border: none;
      position: relative;
      z-index: 3;
      color: #333333;
      background-color: transparent;

      &:focus + .search-icon {
        color: #C7000B;
      }
    }

    .search-button {
      cursor: pointer;
      color: #FFFFFF;
      font-size: 16px;
      background-color: #C7000B;
      height: 100%;
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      border-radius: 48px 0 0 48px;

      &:active {
        opacity: .8;
      }
    }
  }
</style>
